import React, { useState } from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { Link } from "gatsby"
import styled from "styled-components/macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RecipeList from "../components/recipeList"
import CategoryNav from "../components/categoryNav"

const SearchBar = styled.div`
  background: #555;
  display: flex;
  padding: 15px 10px 15px 10px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
`

const SearchInput = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 4px;
`

const SearchResult = styled.div`
  padding: 15px;
  color: #fff;
  border-bottom: 1px solid #555;
`
const SearchTitle = styled.h2`
  font-weight: bold;
`
const SearchDescription = styled.p`
  font-size: 14px;
  color: #ccc;
`

const SearchPage = ({ data }) => {
  const { store, index } = data.localSearchRecipes
  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  return (
    <Layout pageTitle="Search" hideSearch={true} navigateBack={true}>
      <SEO title="Search" />
      <SearchBar>
        <SearchInput
          type="text"
          onChange={e => {
            setQuery(e.target.value)
          }}
          ref={input => input && input.focus()}
        />
      </SearchBar>
      <div>
        {results.map(r => (
          <Link key={r.slug} to={`/recipes/${r.slug}`}>
            <SearchResult>
              <SearchTitle>{r.title}</SearchTitle>
              <SearchDescription>{r.description}</SearchDescription>
            </SearchResult>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SearchQuery {
    localSearchRecipes {
      store
      index
    }
  }
`

export default SearchPage
